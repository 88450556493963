import {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { useAuth } from '../../contexts/auth.context';

const InviteVendorPage = () => {
  const {currentUser} = useAuth();
  const textRef = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/embed/v2.js';
    script.setAttribute('type', 'text/javascript');
    document.body.appendChild(script);

    script.addEventListener('load', () => {
        //@ts-ignore
        if(window.hbspt){
            //@ts-ignore
            hbspt.forms.create({
            region: "na1",        
            portalId: "23399414",        
            formId: "4de9bb4f-0f22-43b6-a720-cc9e9bf58eea",
            target: '#hubspotForm',
            onFormReady: () => {
              var iframe = document.getElementsByClassName("hs-form-iframe")[0] as HTMLIFrameElement;
              if(iframe?.contentWindow?.document) {
                var formDocument = iframe.contentWindow.document;
                var requesterCompany = formDocument.getElementsByName("requested_by_search_company")[0];
                if(requesterCompany && currentUser?.agency_agencyName){
                  requesterCompany.setAttribute("value", currentUser.agency_agencyName);
                }
                var requesterUser = formDocument.getElementsByName("requested_by_user_")[0];
                if(requesterUser && currentUser?.user_name){
                  requesterUser.setAttribute("value", currentUser.user_name);
                }
                var submitButton = formDocument.getElementsByClassName("hs-button")[0];
                if(submitButton){
                  var asideColor = getComputedStyle(document.documentElement).getPropertyValue('--kt-aside-bg-color');
                  submitButton.setAttribute("style", submitButton.getAttribute("style") + `;background-color: ${asideColor}; border-color: ${asideColor};`);
                }
              }
            },
            onFormSubmitted: () => {
              if(textRef.current) {
                textRef.current.style.display = "none";
              }
            }
          });
        }
    });

    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }

  }, [currentUser]);

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        VENDOR INVITE
      </PageTitle>
      <div className='card bg-white min-h-750px' style={{border: 'solid 1px #E8E8E8'}}>
      <div className='card-header pt-3 pb-2 d-flex align-items-center'>
        <h3 className='card-title align-items-start flex-row'>
          <span className='card-label fw-light fs-3 text-primary'>Vendor Invite</span></h3>
      </div>

      <div className='card-body py-6'>
      <p style={{ fontSize: "16px" }} ref={textRef}>
      If you would like to invite your search vendors to join the MaestroX community, please complete the form below: 
      </p>
      <div id="hubspotForm"></div>
      </div>
    </div>
    </>
  )
}

const InviteVendorWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>VENDOR INVITE</PageTitle>
      <InviteVendorPage />
    </>
  )
}

export {InviteVendorWrapper}
