/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
import {FC, useState} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {Tooltip, OverlayTrigger} from 'react-bootstrap'
import {checkIsActive, KTSVG, WithChildren} from '../../../helpers'
import {useLayout} from '../../core'

type Props = {
  to: string
  title: React.ReactNode
  icon?: string
  fontIcon?: string
  className?: string
  hasBullet?: boolean
  bsTitle?: string
  outside?: boolean
  resource?: boolean
}

const WithOverlay: FC<Props & WithChildren> = ({bsTitle, children}) => {
  return (
    <OverlayTrigger
      placement='right'
      delay={{show: 250, hide: 400}}
      overlay={(props) => (
        <Tooltip id='button-tooltip' {...props}>
          {bsTitle}
        </Tooltip>
      )}
    >
      <>{children}</>
    </OverlayTrigger>
  )
}

const MenuItem: FC<Props & WithChildren> = ({
  to,
  className,
  outside,
  title,
  fontIcon,
  bsTitle,
  hasBullet,
  icon,
  children,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config
  const overlayAttributes = bsTitle
    ? {
        'data-bs-toggle': 'tooltip',
        'data-bs-trigger': 'hover',
        'data-bs-dismiss': 'click',
        'data-bs-placement': 'right',
        'data-bs-original-title': bsTitle,
      }
    : {}

  return (
    <div className={clsx('menu-item', isActive && 'here show fw-semibold', className)}>
      {outside ? (
        <a href={to} target='_blank' className={clsx('menu-link menu-center', {active: isActive})}>
          {fontIcon && aside.menuIcon === 'font' && (
            <>
              <span className='menu-icon me-0'>
                <i className={clsx('bi', fontIcon, 'fs-2')}></i>
              </span>
              <span className='menu-title'>{title}</span>
            </>
          )}
        </a>
      ) : (
        <>
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}
            {icon && aside.menuIcon === 'svg' && (
            <>
              <span className='menu-icon'>
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
              <Link className='menu-link menu-center' to={to} {...overlayAttributes}>
                <span className='menu-title'>{title}</span>
              </Link>
            </>
            )}
            {fontIcon && aside.menuIcon === 'font' ? (
              <>
                <Link className='menu-link menu-center' to={to} {...overlayAttributes}>
                  <span className='menu-icon me-0'>
                    <i className={clsx('bi', fontIcon)}></i>
                  </span>
                </Link>
                
              </>
            ) : ''}
          {children}
        </>
      )}
    </div>
  )
}

const AsideMenuItem: FC<Props & WithChildren> = (props) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleSubMenuToggle = (e: React.MouseEvent) => {
    e.preventDefault(); 
    e.stopPropagation(); 
    setIsSubMenuOpen((prev) => !prev);
  };

  if (props.resource) {
    return (
      <div className={clsx('menu-item menu-accordion', { 'show': isSubMenuOpen })}>
        <div className="menu-link"  style={{ cursor: "pointer" }}>
              <span className='menu-icon' >
                {props.icon && <KTSVG path={props.icon} className='svg-icon-2'   />}
              </span>
            <span className='menu-title' onClick={handleSubMenuToggle} style={{ marginTop: '-20px',marginLeft:'70px' }}>{props.title}</span>
            <span style={{ marginLeft:'150px',marginTop:'-13px' }} onClick={handleSubMenuToggle} className={clsx('menu-arrow', { 'rotate-180': isSubMenuOpen })}></span>
        </div>
        {isSubMenuOpen && (
            <div className="menu-sub menu-sub-accordion" style={{ marginLeft: '-90px', marginTop: '30px' }}>
            <Link className='menu-link menu-center' to="/resources" onClick={() => setIsSubMenuOpen(false)}>
                <span className='menu-title' >Board/Effective Dates</span>
              </Link>
            </div>
        )}
      </div>
    );
  }


  if (props.bsTitle) {
    return (
      <WithOverlay {...props}>
        <MenuItem {...props}>{props.children}</MenuItem>
      </WithOverlay>
    )
  }

  return <MenuItem {...props}>{props.children}</MenuItem>
}
export {AsideMenuItem}