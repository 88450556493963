/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {AsideMenu} from './AsideMenu'
import { AsideMenuItem } from './AsideMenuItem'

type Props = {
  isOpenMobile: boolean
}

const AsideDefault = ({isOpenMobile}:Props) => {

  const {classes} = useLayout()
  const [isOpen, setIsOpen] = useState(false);

  const handleAside = () => {
    setIsOpen(!isOpen);
  }
  
  return (
    <>
      <div
        id='kt_aside'
        style={{width: isOpen ? '17%' : 'auto'}}
        className={`${clsx('aside pt-5 pt-lg-0 ', classes.aside.join(' '))} ${isOpen ? 'open' : ''}`}
        data-kt-drawer='true'
        data-kt-drawer-name='aside'
        data-kt-drawer-activate='{default: true, lg: false}'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'80px', '300px': '100px'}"
        data-kt-drawer-direction='start'
        data-kt-drawer-toggle='#kt_aside_mobile_toggle'
        onClick={handleAside}
        
      >
        <KTSVG path='/media/ATR/icons/aside-arrow.svg' className='svg-icon svg-icon-40 expand-aside-icon' />
        <div className='aside-logo  py-6' id='kt_aside_logo'>
          <Link to='/dashboard' className='d-flex align-items-center'>
            <img
              src={toAbsoluteUrl('/media/ATR/logos/MaestroXLogo-justX.png')}
              alt='logo'
              className='h-38px logo'
            />
          </Link>
        </div>
        <div className='aside-menu flex-column-fluid' id='kt_aside_menu'>
          <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
        </div>
        <div className='aside-footer flex-column-auto' id='kt_aside_footer'>
          {/* begin::Menu */}
          <div className='d-flex justify-content-center flex-column'>
            <AsideMenuItem
              to='/vendorinvite'
              title="Invite"
              icon='/media/ATR/icons/Icon invite.svg'
              className=''
            />
            <AsideMenuItem
              to='/contact'
              title="Contact Us"
              icon='/media/ATR/icons/Icon email.svg'
              className=''
            />
            <AsideMenuItem
              to='/faqs'
              title="FAQs"
              icon='/media/ATR/icons/Icon feather-help-circle.svg'
              className=''
            />
            <hr 
              className='my-0 bg-white text-white border-0' 
              style={{opacity: 1, height: '1px', margin: '0 18px'}}
            />
            <AsideMenuItem
              to='/logout'
              title="Log Out"
              icon='/media/ATR/icons/Icon feather-log-out.svg'
              className='logout-menu-item'
            />
          </div>
          {/* end::Menu */}
        </div>
      </div>
      <div
      id='kt_aside_mobile'
      className={`${isOpenMobile ? 'open' : ''} ${clsx('aside pt-5 pt-lg-0 position-fixed', classes.aside.join(' '))}`}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'80px', '420px': 'calc(100% - 60px)', '300': 'calc(100% - 40px)'}"
      data-kt-drawer-direction='start'
    >
      <div className={`aside-menu`} id='kt_aside_menu'>
        <Link to="/dashboard" className='d-block py-3 text-white border-bottom'>Dashboard</Link>
        {/* <Link to="/alerts" className='d-block py-3 text-white border-bottom'>Alerts</Link> */}
        {/* <Link to="/downloads" className='d-block py-3 text-white border-bottom'>Downloads</Link> */}
        <Link to="/orders/report" className='d-block py-3 text-white border-bottom'>Report</Link>
        {/* <Link to="/orders" className='d-block py-3 text-white border-bottom'>New Order</Link> */}
        <Link to="/logout" className='d-block py-3 text-white'>Logout</Link>
      </div>
    </div>
    </>
  )
}

export {AsideDefault}
