import { ICreateOrder, IOwnerData } from "../models/modal.model";
import { BuyerModel } from "../models/orders/buyer.model";
import { OrderModel } from "../models/orders/order.model";
import { OwnerModel } from "../models/orders/owner.model";
import { getAuth, setUpAPI } from "../utils/auth.utils";
import { base64Decode, xorDecrypt } from "../utils/global.utils";


const { REACT_APP_API_URL } = process.env;
const { REACT_APP_API_NOTES } = process.env;

export const newOrder = async (order: ICreateOrder) => {
  const url = `${REACT_APP_API_URL}/v1/services/order`;
  const owners: OwnerModel[] = [];
  const buyers: BuyerModel[] = [];
  order.propertyOwner.owners.forEach((owner: IOwnerData) => {
    owners.push({
      type: owner.ownerType,
      firstName: owner.firstName,
      middleName: owner.middleName,
      lastName: owner.lastName
    });
  });
  order.propertyOwner.buyers.forEach((buyer: IOwnerData) => {
    buyers.push({
      type: buyer.ownerType,
      firstName: buyer.firstName,
      middleName: buyer.middleName,
      lastName: buyer.lastName
    });
  });

  const orderData: OrderModel = {
    transaction_agencyName: order.orderType.agencyName,
    transaction_agencyOrderId: order.orderType.orderNumber,
    transaction_correlationId: order.orderType.correlationId,
    transaction_searchLength: order.orderType.countySearch,
    transaction_searchType: order.orderType.transactionType,
    transaction_address: order.propertyAddress.addressLine1,
    transaction_addressTwo: order.propertyAddress.addressLine2,
    transaction_county: order.propertyAddress.county,
    transaction_city: order.propertyAddress.city,
    transaction_state: order.propertyAddress.state,
    transaction_zip: order.propertyAddress.zipcode,
    tgOrder_parcelId: order.propertyAddress.parcelId,
    tgOrder_additionalParcelId: order.propertyAddress.additionalId,
    transaction_block: order.propertyAddress.block,
    transaction_lot: order.propertyAddress.lot,
    transaction_qualifier: order.propertyAddress.qualifier,
    owners: owners,
    buyers: buyers,
    tgOrder_loanAmount: `${order.orderReview.price}`,
    transaction_comments: order.orderReview.comments
  }

  const response = await setUpAPI(url, orderData, 'POST').catch((err) => {
    return err;
  });

  const body = await response.json();
  if(body.success && body.success.msg) {
    return body.success.msg;
  }
  else {
    return body;
  }
}

export const getOrderList = async (status:string|undefined) => {
  let finalStatus = '';
  if(status) {
    finalStatus = status !== 'all' ? status : '';
  }
  const url = `${REACT_APP_API_URL}/v1/services/orders?status=${finalStatus}`;
  const response = await setUpAPI(url, undefined, 'GET').catch((err) => {return err;});
  const body = await response.json();
  
  if(body.success && body.success.msg) {
    return body.success.msg;
  } else {
    return body;
  }
}

export const getOrderDetails = async(orderId:string) => { 

  const decodeOid = xorDecrypt(base64Decode(orderId));
  const url = `${REACT_APP_API_URL}/v1/services/order/${decodeOid}`;

  const response = await setUpAPI(url,undefined, 'GET').catch(err => {return err});
  
  const body = await response.json();

  if(body && body.success && body.success.msg[0]) {
    const newOrder:OrderModel = body.success.msg[0];
    return newOrder;
  } else if(body && body.error) {
    return body.error;
  }
  
}

export const getPricingDetails = async(transactionId : number) => 
  {
    //transactionId:string
    const url =  `${REACT_APP_API_URL}/v1/services/getPricingDetails?transactionId=${transactionId}` 
    const response = await setUpAPI(url,undefined, 'GET').catch(err => {return err}); 
    const body = await response.json();
    return body

  }

export const getDocument = async(documentPath: string) => {
  const url = `${REACT_APP_API_URL}/v1/services/invoker/docgen/pdf2string`;
  var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
  
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
          "documents": [documentPath],
          "authKey": "d68c908ad8a31c3963f42181f932458461e5a379"
      })
    };
  
    const response = await fetch(url, requestOptions).catch((err) => {return err;});
    const body = await response.json();
    return body;
}

 export const getStatus = (processingQueue:string|undefined, status:string|undefined) => {
    var currentStatus = "Unknown";
    var description = "";

    if (status === "Withdrawn") {
      currentStatus = "Cancelled";
      description = "Cancelled";
    } else if (processingQueue === "Complete") {
      currentStatus = "Delivered";
      description = "Delivered";
    } else if (status === "Wait") {
      currentStatus = "Waiting";
      description = "Client Action Required";
    } else if (processingQueue === "Delivery") {
      if (status === "Hold") {
        currentStatus = "On Hold";
        description = "Waiting for Ancillary Search(es)";
      } else if (status === "" || isNullorUndefined(status)) {
        currentStatus = "Delivery";
        description = "Delivery";
      }
    } else if (processingQueue === "Admin") {
      if (status === "Hold") {
        currentStatus = "On Hold";
        description = "Additional Search(es) Requested";
      } else if (status === "" || isNullorUndefined(status)) {
        currentStatus = "Active";
        description = "Examining";
      }
    } else if (processingQueue === "Order") {
      if (status === "" || isNullorUndefined(status)) {
        currentStatus = "Active";
        description = "Retrieving County Search";
      }
    } else if (processingQueue === "Reader") {
      if (status === "" || isNullorUndefined(status)) {
        currentStatus = "Active";
        description = "Examining";
      } else if (status === "Hold") {
        currentStatus = "Active";
        description = "Retrieving County Search";
      }
    } else if (processingQueue === "QC") {
      if (status === "" || isNullorUndefined(status)) {
        currentStatus = "Active";
        description = "Examining";
      } else if (status === "Hold") {
        currentStatus = "Active";
        description = "Examining";
      }
    } else {
      currentStatus = "Unknown";
      description = "Unknown";
    }

    return { status: currentStatus, description: description };
};

export const localStatus = (processingQueue:string|undefined, status:string|undefined) => {
  let currentStatus = "unknown";

  if(status === "Hold" || status === "Wait" || status === "Active") {
    if(processingQueue === "Complete") {
      currentStatus = "done"
    } else {
      currentStatus = "progress"
    }
  } else if(status === "Withdrawn") {
    currentStatus = "ignore"
  } else {
    currentStatus = "received";
  }

  return { status: currentStatus };
}



export const filterOrders = async (searchText:string, status:string|undefined = '') => {
  const url = `${REACT_APP_API_URL}/v1/services/search?filenumber=${searchText}&status=${status}`;

  const response = await setUpAPI(url,undefined, 'GET').catch(err => {return err});
  
  const body = await response.json();

  if(body && body.success && body.success.msg) {
    return body.success.msg;
  } else if(body && body.error) {
    throw body.error;
  }
}

  function isNullorUndefined(value:any) {
    return value === null || value === undefined;
  }

  export const getSearcherCompany = async() => {
    const url = `${REACT_APP_API_URL}/v1/services/orgSearcherCompanies`;
    const response = await setUpAPI(url,undefined, 'GET').catch(err => {return err});
    const body = await response.json();
    return body;
  }

  export const updateNotes = async (id:number, note:string, commenter:string|undefined) => {
    const url = `${REACT_APP_API_URL}/v1/services/comments/update`;
    let order = {};
  
    if(id && note) {
      order = {
        "order": {
          "order": {
            "transaction": {
              "id": id,
              "comments": note,
              "commenter": commenter ? commenter : ''
            }
          }
        }
      }
    }
  
    const response = await setUpAPI(url, order, 'PUT').catch((err) => {return err;});
    const body = await response.json();
    return body.success.msg
  }
  export const rushButton = async(userEmail: string,clientOrder:string,searchCompany:number,AEStransactionID:number) => {
    const url = `${REACT_APP_API_URL}/v1/services/notes/special-request`;
    let requestOptions = {};
    
    /*
     requestOptions = {
          "button_id" : "Rush",
          "order_id" : clientOrderId,
          "order":{"note":{"transactionId":AEStransactionID,"userID":userEmail,"read":false}}
        };
        */
        requestOptions =  {
          "button_id" : "Rush",
          "order_id" : clientOrder,
          "search_company_id": searchCompany,
          "order":{"note":{"transactionId":AEStransactionID,"userEmail":userEmail,"read":false}}
      }
        

      const response = await setUpAPI(url, requestOptions,'POST').catch((err) => {return err;});
      const body = await response.json();
      return body.success.msg;
    
  }

  export const updateSearchCompany = async(orderId: number, searchCompany: number) => {
    const url = `${REACT_APP_API_URL}/v1/services/searcherCompany/order/${orderId}`;
    let assignData = {} 
    if(searchCompany){
      assignData = {
        "searchCompanyId": searchCompany
      }
    }
    const response = await setUpAPI(url, assignData, 'POST').catch((err) => {
      return err;
    });
    
    const body = await response.json();

  return body.message;

}


export const updateSpecialRequest = async (buttonType:string,apiDatatoSend:any,messageText:string) => {  
  const url = `${REACT_APP_API_URL}/v1/services/notes/special-request`;
  let requestOptions = {};

  requestOptions = {
    "button_id": buttonType,
    "order_id": apiDatatoSend.fileNumber,
    "appended_note": messageText,
    "order": {
      "note": {
        "transactionId": apiDatatoSend.transactionId,
        "userEmail": apiDatatoSend.email,
        "read": false
      }
    }
  }


  const response = await setUpAPI(url, requestOptions, 'POST').catch((err) => { return err; });
  const body = await response.json();

  
  return body;
}

export const getOrderReportDetails = async (reportingOrgId:number, api_token: string,startDate:string,endDate:string,status:string) => {
  const url = `${REACT_APP_API_URL}/v1/services/getReportDetails?reportingOrgId=${reportingOrgId}&transactionStartDate=${startDate}&transactionEndDate=${endDate}&status=${status}`;
  let requestOptions = {
    "reportingOrgId": reportingOrgId,
    "transactionStartDate": startDate,
    'transactionEndDate': endDate,
    "status": status
  };
  const response = await setUpAPI(url, requestOptions, 'POST').catch((err) => {return err;});
  const body = await response.json();
  return body;
}